import * as React from 'react'

import { graphql } from "gatsby";
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Heading } from 'theme-ui'

import Layout from '../components/layout'

const BlogPost = ({ data }) => {
  return (
    <Layout slugs={data}>
      <title>{data.mdx.frontmatter.title} | DFS Crunch</title>
      <Heading>{data.mdx.frontmatter.title}</Heading>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    allMdx: allMdx(sort: {fields: frontmatter___ordering, order: DESC}) {
      nodes {
        id
        slug
        frontmatter {
          ordering
          title
        }
      }
    }
    mdx: 
      mdx(id: {eq: $id}) {
        frontmatter {
          title
        }
        body
      }
  }
`

export default BlogPost
